import React from "react";

function LayoutComponent(props) {
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <svg
            version="1.1"
            id="Wave"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1366 114"
            className="separator-first"
          >
            <g>
              <path className="st0" d="M1366,0L0,0l0,26c640-88,851,121,1366,77V0z"></path>
            </g>
            <g>
              <g>
                <path
                  className="st1"
                  d="M170.9,8.1c57.2-3.7,114.5-5.2,171.8-4.2c57.3,1,114.5,4.7,171.5,10.8c57,6.1,113.7,14.5,170.1,24.2 c56.5,9.7,112.7,20.7,169.1,30.8c56.4,10.2,112.9,19.4,169.7,26.3c56.8,6.9,114,11.1,171.3,12.2c57.2,1.2,114.6-0.7,171.7-5.3 c-57.1,4.9-114.4,7.2-171.7,6.3c-57.3-0.9-114.5-5-171.4-11.7C966,91,909.5,81.6,853.1,71.6c-56.4-10.1-112.6-21-169.1-30.6 c-56.4-9.7-113.1-18.3-170-24.4c-56.9-6.2-114.1-9.9-171.3-11.1C285.4,4.2,228.1,5.5,171,9C113.8,12.7,56.8,18.6,0,26 C56.8,18.3,113.8,12.1,170.9,8.1z"
                ></path>
              </g>
            </g>
          </svg>
          <div className="container-fluid first-section bg-almost-white">
            {/* RENDER CHILDREN */}
            {props.children}
          </div>
          <svg
            version="1.1"
            id="Wave"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1366 114"
            className="separator-second"
          >
            <g>
              <path className="st0" d="M1366,0L0,0l0,26c640-88,851,121,1366,77V0z"></path>
            </g>
            <g>
              <g>
                <path
                  className="st1"
                  d="M170.9,8.1c57.2-3.7,114.5-5.2,171.8-4.2c57.3,1,114.5,4.7,171.5,10.8c57,6.1,113.7,14.5,170.1,24.2 c56.5,9.7,112.7,20.7,169.1,30.8c56.4,10.2,112.9,19.4,169.7,26.3c56.8,6.9,114,11.1,171.3,12.2c57.2,1.2,114.6-0.7,171.7-5.3 c-57.1,4.9-114.4,7.2-171.7,6.3c-57.3-0.9-114.5-5-171.4-11.7C966,91,909.5,81.6,853.1,71.6c-56.4-10.1-112.6-21-169.1-30.6 c-56.4-9.7-113.1-18.3-170-24.4c-56.9-6.2-114.1-9.9-171.3-11.1C285.4,4.2,228.1,5.5,171,9C113.8,12.7,56.8,18.6,0,26 C56.8,18.3,113.8,12.1,170.9,8.1z"
                ></path>
              </g>
            </g>
          </svg>
        </div>
      </div>
    </>
  );
}
export default LayoutComponent;
