import "./PaymentForm.css";

import { prettyDate, prettyTimezone } from "./helper/date.helper";
import { useEffect, useState } from "react";

import CurrencyInput from "react-currency-input-field";
import LayoutComponent from "./Layout";
import React from "react";
import { eventHelpers } from "./helper/events.helpers";
import i18next from "i18next";
import { loadStripe } from "@stripe/stripe-js";
import { toast } from "react-toastify";

const backendUrl = "https://backend.virace.app";
// const backendUrl = "http://localhost:3000";

// const stripePromise = () => loadStripe("pk_test_ufK0pOvW0maDXAmfleWS0Xgv000cKOcfta");
const stripePromise = () => loadStripe("pk_live_JR4ti1ghVT5ImK4mMyGrKBmE00CrGDBMDj");
const initialDonationData = {
  id: "",
  donationMode: "",
  name: "",
  email: "",
  amount: 0,
  finalAmount: 0,
  paymentCurrency: "",
};

function PaymentComponent(props) {
  // Get hashed donationId from the URL
  const donationId = props.match.params.donation_id;
  const query = new URLSearchParams(props.location.search);
  const [donationData, setDonationData] = useState(initialDonationData);
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [pledgedAmount, setPledgedAmount] = useState(0);
  const [eventInformation, setEventInformation] = useState();

  async function fetchDonationData() {
    try {
      const res = await fetch(`${backendUrl}/api/v1/donations/` + donationId);
      setDonationData(await res.json());
    } catch (error) {}
  }

  useEffect(() => {
    fetchDonationData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (query.get("paymentFailed")) {
      toast.error(i18next.t("payment_failed"), { theme: "colored" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const convertTime = function (input, separator) {
    var pad = function (input) {
      return input < 10 ? "0" + input : input;
    };
    return [pad(Math.floor(input / 3600)), pad(Math.floor((input % 3600) / 60)), pad(Math.floor(input % 60))].join(
      typeof separator !== "undefined" ? separator : ":"
    );
  };

  const isDistanceRace = () => eventInformation?.room?.timingType === "distance";

  const eventDistance = () => {
    if (eventInformation && eventInformation.time && eventInformation.room) {
      return isDistanceRace()
        ? eventHelpers.metersToKMDecimal(eventInformation.room.distance, 2)
        : convertTime(eventInformation.room.distance);
    } else {
      return "--";
    }
  };

  const resultTime = () => {
    if (eventInformation && eventInformation.time && eventInformation.room) {
      return isDistanceRace() ? convertTime(eventInformation.time) : convertTime(eventInformation.room.distance);
    } else {
      return convertTime("00");
    }
  };

  const resultDistance = () => {
    let distance = "--";
    if (eventInformation && eventInformation.time && eventInformation.room) {
      distance = isDistanceRace()
        ? eventHelpers.metersToKMDecimal(eventInformation.room.distance, 2)
        : eventHelpers.metersToKMDecimal(eventInformation.time, 2);
    }

    return `${distance} km`;
  };

  function intToFloat(num, decPlaces) {
    return num.toFixed(decPlaces);
  }

  // Update the form value if data changes
  useEffect(() => {
    setEventInformation(donationData.participation);
    setPledgedAmount(intToFloat(donationData.amount / 100, 2) ?? "0");
    setPaymentAmount(intToFloat(donationData.finalAmount / 100, 2) ?? "0");
  }, [donationData]);

  // Handle form changes
  const handleChange = (newAmount) => {
    setPaymentAmount(newAmount);
  };

  const redirect_page = () => {
    props.history.push("/" + donationData.token);
  };
  const handleClick = async () => {
    console.log(donationData);
    // Call your backend to create the Checkout Session
    const response = await fetch(`${backendUrl}/api/v1/donations/checkout-session`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        donationId: donationData.id,
        token: donationData.token,
        successUrl: `https://charity.virace.app/${donationData.token}?paymentSuccess=1`,
        cancelUrl: `https://charity.virace.app/payment/${donationId}?paymentFailed=1`,
        amount: Math.round(parseFloat(paymentAmount, 10) * 100),
      }),
    });

    const session = await response.json();

    // Get Stripe.js instance
    const stripe = await stripePromise();
    const result = await stripe.redirectToCheckout({
      sessionId: session.id,
    });
    if (result.error) {
      alert("error");
    }
  };

  document.title = "Payment Page";
  return (
    <LayoutComponent>
      <div className="row">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <button className="btn btn-outline-primary" onClick={redirect_page}>
                {" "}
                <i className="fa fa-long-arrow-left mr-2"></i>
                {i18next.t("donation")}
              </button>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-6">
              <div className="card">
                <div className="card-body event-info-block">
                  <h6>{i18next.t("event_information")}</h6>
                  <ul>
                    <li>
                      <strong>{isDistanceRace() ? i18next.t("distance") : i18next.t("time")}</strong>
                      <span>{eventDistance()}</span>
                    </li>
                    <li>
                      <strong>{i18next.t("date")}</strong>
                      {eventInformation && eventInformation.room && (
                        <span>
                          {prettyDate(eventInformation.room.startDate)}
                          <small>{prettyTimezone(eventInformation.room.startDate)}</small>
                        </span>
                      )}
                    </li>
                    <li>
                      <strong>{i18next.t("web")}</strong>
                      {eventInformation && eventInformation.room && <span>{eventInformation.room.web_link}</span>}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card">
                <div className="card-body event-info-block">
                  <h6>{i18next.t("Runner-Information")}</h6>
                  <ul>
                    <li>
                      <strong>{i18next.t("User_name")}</strong>
                      <span>{eventInformation?.user?.username}</span>
                    </li>
                    <li>
                      <strong>{i18next.t("time")}</strong>
                      <span> {resultTime()}</span>
                    </li>
                    <li>
                      <strong>{i18next.t("Completed_Distance")}</strong>
                      <span>{resultDistance()}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body event-info-block my-4">
                  <h6 className="mb-1">{i18next.t("Payment")}</h6>
                  <div className="row">
                    <div className="col-md-12">
                      <label>{i18next.t("name")} </label>
                    </div>
                    <div className="col-md-12 mb-3">
                      <input type="text" name="name" className="form-control" value={donationData.name} readOnly />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <label>{i18next.t("E-mail")} </label>
                    </div>
                    <div className="col-md-12 mb-3">
                      <input type="email" name="name" className="form-control" value={donationData.email} readOnly />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <label>{i18next.t("Pledged_Amount")} </label>
                    </div>
                    <div className="col-md-12 mb-3">
                      <CurrencyInput
                        name="amount"
                        className="form-control"
                        value={pledgedAmount}
                        decimalsLimit={2}
                        suffix={" " + donationData.paymentCurrency}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <label>{i18next.t("Donation_Amount")} </label>
                    </div>
                    <div className="col-md-12 mb-3">
                      <CurrencyInput
                        name="paymentamount"
                        className="form-control donation-amount"
                        value={paymentAmount}
                        onValueChange={handleChange}
                        decimalsLimit={2}
                        suffix={" " + donationData.paymentCurrency}
                        required
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 mt-3">
                      <button className="btn btn-success" onClick={handleClick}>
                        {i18next.t("continue_to_checkout")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutComponent>
  );
}
export default PaymentComponent;
