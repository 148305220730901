export const eventHelpers = {
    toHHMMSS,
    secToHours,
    metersToKMDecimal,
    numberWithCommas,
  };
  
  const _ = require("lodash");
  
  function toHHMMSS(secs) {
    var date = new Date(1970, 0, 1);
    date.setSeconds(secs);
    return date.toTimeString().replace(/.*(\d{2}:\d{2}:\d{2}).*/, "$1");
  }
  
  function secToHours(d) {
    d = Number(d);
    return Math.floor(d / 3600)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, "'");
  }
  
  function metersToKMDecimal(d, precision = 2) {
    d = Number(d);
    return _.round(d / 1000, precision).toFixed(precision);
  }
  
  function numberWithCommas(d) {
    d = Number(d);
    return d.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
  }
  