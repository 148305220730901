import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import translationsEN from './en/en.json'
import translationsDE from './de/de.json'
import translationsFR from './fr/fr.json'
import translationsES from './es/es.json'
import translationsIT from './it/it.json'

const resources = {
  en: {
    translation: translationsEN
  },
  de: {
    translation: translationsDE
  },
  fr: {
    translation: translationsFR
  },
  es: {
    translation: translationsES
  },
  it: {
    translation: translationsIT
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources,
    // fallbackLng: 'en', // default language
    lng:'en',
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });
  
  export default i18n;