import "./Home.css";

import React, { Component } from "react";
import { prettyDate, prettyTimezone } from "./helper/date.helper";

import CurrencyInput from "react-currency-input-field";
import { Globe } from "react-bootstrap-icons";
import LayoutComponent from "./Layout";
import axios from "axios";
import calendar from "../assets/images/calendar.svg";
import calendar_distance from "../assets/images/distance_light.svg";
import { eventHelpers } from "./helper/events.helpers";
import i18next from "i18next";
import { toast } from "react-toastify";

export class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      donation: "fixed",
      name: "",
      email: "",
      amount: "",
      data: undefined,
      distance: "",
      user: undefined,
      eventTitle: "",
      feedImage: "",
      donations_data: [],
      event_info: [],
      paymentCurrency: "EUR",
      checked: false,
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onAmountChange = (newAmount) => {
    //console.log(newAmount);
    this.setState({ amount: newAmount });
  };

  anonymous = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  };
  onSubmit = (e) => {
    e.preventDefault();
    const { donation, name, email, amount, paymentCurrency, checked } = this.state;
    const token = this.props.match.params.token;
    const donationData = {
      token: token,
      donationMode: donation,
      name: name,
      // amount needs to be transmitted in cents
      // Example: 15.35 ==> 1535
      amount: Math.round(parseFloat(amount, 10) * 100),
      email: email,
      paymentCurrency: paymentCurrency,
      anonymous: checked,
    };
    axios.post("https://backend.virace.app/api/v1/donations/", donationData).then((response) => {
      if (response.status === 201 && response != null) {
        alert("Personalised Donation Save Successfuly");
        window.location.reload();
      } else {
        alert("Error in saving Data");
      }
    });
  };

  componentDidMount() {
    const token = this.props.match.params.token;
    const query = new URLSearchParams(this.props.location.search);
    if (query.get("paymentSuccess")) {
      toast.success(i18next.t("payment_success"), { theme: "colored" });
    }
    axios
      .get("https://backend.virace.app/api/v1/donations/token/" + token)
      .then((response) => {
        if (response.status === 200 && response != null) {
          this.setState({
            data: response.data,
            donations_data: response.data.donations,
            distance: response.data.room.distance,
            user: response.data.user,
            eventTitle: response.data.room.event_title,
            feedImage: response.data.room.feedImage?.url,
            event_info: response.data.room,
          });
        } else {
          console.log("problem");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  render() {
    const { name, email, amount, paymentCurrency, data, user, eventTitle, donations_data, checked, event_info, feedImage } = this.state;
    const token = this.props.match.params.token;
    const fixedDonations = donations_data.filter((X) => X["donationMode"] === "fixed");
    const sumFixed = { CHF: 0, EUR: 0, USD: 0 };
    fixedDonations.forEach((element) => {
      sumFixed[element.paymentCurrency] += element.amount;
    });

    const variableDonations = donations_data.filter((X) => X["donationMode"] === "variable");
    const sumVariable = { CHF: 0, EUR: 0, USD: 0 };
    variableDonations.forEach((element) => {
      sumVariable[element.paymentCurrency] += element.amount;
    });

    document.title = "Donation page of " + (user?.username ?? "") + " for " + eventTitle;
    return (
      <LayoutComponent>
        <div className="row">
          <div className="container mb-2">
            <div className="row mb-2">
              <div className="col-md-6">
                <div className="card">
                  <div className="card-body">
                    <h2>{event_info.event_title}</h2>
                    <div className="event-card">
                      <div className="main_info">
                        {event_info.timingType === "distance" && (
                          <div className="race_element distance">
                            <span className="icon">
                              <img src={calendar_distance} alt="distance" />
                            </span>
                            <div className="label_value">
                              <label>{i18next.t("distance")}</label>
                              <span className="value">{`${eventHelpers.metersToKMDecimal(event_info.distance, 0)} km`}</span>
                            </div>
                          </div>
                        )}
                        {event_info.timingType === "time" && (
                          <div className="race_element distance">
                            <span className="icon">
                              <img src={calendar_distance} alt="distance" />
                            </span>
                            <div className="label_value">
                              <label>{i18next.t("time")}</label>
                              <span className="value">{eventHelpers.toHHMMSS(event_info.distance)}</span>
                            </div>
                          </div>
                        )}
                        <div className="race_element date">
                          <span className="icon">
                            <img src={calendar} alt="calendar"></img>
                          </span>
                          <div className="label_value">
                            <label>{i18next.t("start_between")}</label>
                            <span className="value until">
                              {prettyDate(event_info.startDate)}
                              <small>{prettyTimezone(event_info.startDate)}</small>
                            </span>
                            {(event_info.raceType === "open_window" || event_info.raceType === "segment") && (
                              <span className="value until">
                                {prettyDate(event_info.endDate)}
                                <small>{prettyTimezone(event_info.endDate)}</small>
                              </span>
                            )}
                          </div>
                        </div>
                        {event_info.web_link && (
                          <div className="race_element web_link">
                            <span className="icon">
                              <Globe size={18} color={"#3b515c"} className="icon" />
                            </span>
                            <div className="label_value">
                              <label>{i18next.t("web")}</label>
                              <a href={event_info.web_link} className="value">
                                {event_info.web_link}
                              </a>
                            </div>
                          </div>
                        )}
                        {feedImage && (
                          <div className="event_logo race_element pt-3">
                            <img src={feedImage} alt="location" />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card user-card">
                  <div className="card-body main_info">
                    <h2>{`${user?.firstname} ${user?.lastname}`}</h2>
                    <div className="user_element">
                      <div className="label_value">
                        <label>{i18next.t("username")}</label>
                        <span className="value">{user?.username}</span>
                      </div>
                    </div>
                    <div className="user_element">
                      <div className="label_value">
                        <label>{i18next.t("firstname")}</label>
                        <span className="value">{user?.firstname}</span>
                      </div>
                    </div>
                    <div className="user_element">
                      <div className="label_value">
                        <label>{i18next.t("lastname")}</label>
                        <span className="value">{user?.lastname}</span>
                      </div>
                    </div>
                    {user?.team && (
                      <div className="user_element">
                        <div className="label_value">
                          <label>{i18next.t("team")}</label>
                          <span className="value">{user?.team}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <form className="form" onSubmit={this.onSubmit}>
                      <input type="hidden" className="form-control" id="token" name="token" value={token} readOnly required />
                      <h1>{i18next.t("donation_form_title")}</h1>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label htmlFor="name">{i18next.t("name")}</label>
                          <input
                            type="text"
                            className="form-control"
                            name="name"
                            onChange={this.onChange}
                            value={name}
                            id="Name"
                            placeholder={i18next.t("entername")}
                            required
                          />
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="email">{i18next.t("emailaddress")}</label>
                          <input
                            type="email"
                            className="form-control"
                            name="email"
                            onChange={this.onChange}
                            value={email}
                            id="Email"
                            placeholder={i18next.t("enteremailaddress")}
                            required
                          />
                        </div>
                      </div>

                      <div className="form-row">
                        <div className="form-group col-md-4">
                          <label htmlFor="amount">{i18next.t("amount")}</label>
                          <CurrencyInput
                            id="amount"
                            name="amount"
                            className="form-control"
                            value={amount}
                            onValueChange={this.onAmountChange}
                            placeholder={i18next.t("enteramount")}
                            decimalsLimit={2}
                            prefix={paymentCurrency + " "}
                            required
                          />
                        </div>
                        <div className="form-group col-md-2">
                          <label htmlFor="token">{i18next.t("currency")}</label>
                          <select className="custom-select pull-right" name="paymentCurrency" onChange={this.onChange}>
                            <option value="EUR">EUR</option>
                            <option value="CHF">CHF</option>
                            <option value="USD">USD</option>
                          </select>
                        </div>
                        <div className="col-md-6">
                          <label>{i18next.t("donation_mode")}</label>
                          <div className="form-check">
                            <input
                              type="radio"
                              className="form-check-input"
                              value="fixed"
                              name="donation"
                              id="fixed"
                              checked
                              required
                              onChange={this.onChange}
                            />
                            <label className="form-check-label" htmlFor="fixed">
                              {i18next.t("fixed")}
                            </label>
                          </div>
                          {event_info.timingType === "time" && (
                            <div className="form-check">
                              <input
                                type="radio"
                                className="form-check-input"
                                name="donation"
                                value="variable"
                                id="variable"
                                required
                                onChange={this.onChange}
                              />
                              <label className="form-check-label" htmlFor="variable">
                                {i18next.t("variable")}
                              </label>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <div className="form-check mt-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="checked"
                              id="mycheck"
                              defaultChecked={checked}
                              onClick={this.anonymous}
                            />
                            <label className="form-check-label" htmlFor="mycheck">
                              {i18next.t("anonymous")}
                            </label>
                          </div>
                        </div>
                      </div>
                      <button type="submit" className="btn btn-primary px-5 mt-3">
                        {i18next.t("submit")}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-2">
          <div className="container">
            <div className="row mb-2">
              {Object.values(sumFixed).some((v) => v > 0) && (
                <div className="col-md-6">
                  <div className="card pledged">
                    <div className="card-body">
                      <h2>{i18next.t("total_fixed")}</h2>
                      {Object.entries(sumFixed).map(([c, v]) => {
                        return v > 0 ? (
                          <span key={c} className="badge badge-success mb-2 mr-2 px-3">
                            <h3>{`${(v / 100.0).toFixed(2)} ${c}`}</h3>
                          </span>
                        ) : null;
                      })}
                    </div>
                  </div>
                </div>
              )}
              {Object.values(sumVariable).some((v) => v > 0) && (
                <div className="col-md-6">
                  <div className="card pledged">
                    <div className="card-body">
                      <h2>{i18next.t("total_variable")}</h2>
                      {Object.entries(sumVariable).map(([c, v]) => {
                        return v > 0 ? (
                          <span key={c} className="badge badge-success mb-2 mr-2 px-3">
                            <h3>{`${(v / 100.0).toFixed(2)} ${c} / KM`}</h3>
                          </span>
                        ) : null;
                      })}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card pledged_table">
                  <div className="card-body">
                    <h2>{i18next.t("pledged_donations")}</h2>
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">{i18next.t("name")}</th>
                          <th scope="col">{i18next.t("donation_mode")}</th>
                          <th scope="col">{i18next.t("amount")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data &&
                          data.donations.map((d) => {
                            return (
                              <tr key={d.id}>
                                <td>{d.name}</td>
                                <td>{d.donationMode}</td>
                                <td>
                                  {d.paymentCurrency} {(d.amount / 100).toFixed(2)}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutComponent>
    );
  }
}
export default Home;
