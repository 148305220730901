import * as advancedFormat from "dayjs/plugin/advancedFormat";
import * as dayjs from "dayjs";
import * as timezone from "dayjs/plugin/timezone";
import * as utc from "dayjs/plugin/utc";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

export const today = dayjs();
export const isFuture = (date) => !!(today < localizedDate(date));
export const localizedDate = (date) => {
  // IMPORTANT: parse as UTC Date
  return dayjs.utc(date).tz(dayjs.tz.guess());
};

export const prettyDate = (date) => {
  return localizedDate(date).format("DD. MMM YYYY, HH:mm");
};

export const prettyDay = (date) => {
  return localizedDate(date).format("DD");
};

export const prettyTimezone = (date) => {
  return localizedDate(date).format("Z");
};

export const prettyMonth = (date) => {
  return localizedDate(date).format("MMM");
};
