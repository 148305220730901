import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import "./i18n/i18n";
import "font-awesome/css/font-awesome.min.css";
import "react-toastify/dist/ReactToastify.css";

import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import Home from "./components/Home";
import { Navbar } from "./components/Navbar";
import PaymentForm from "./components/PaymentForm";
import React from "react";
import ReactDOM from "react-dom";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import i18next from "i18next";
import reportWebVitals from "./reportWebVitals";

const lang = localStorage.getItem("lang") || "en";
axios.defaults.baseURL = "http://localhost:3000/";
axios.defaults.headers.common["Accept-Language"] = lang;
i18next.changeLanguage(lang);
const routs = (
  <Router>
    <ToastContainer
      style={{ width: "400px", fontSize: "1.2rem" }}
      position="top-center"
      autoClose={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
    />
    <Navbar />
    <div>
      {/* <Route exact path="/" component={App} /> */}
      <Switch>
        <Route exact path="/payment/:donation_id" component={PaymentForm} />
        <Route exact path="/:token" component={Home} />
      </Switch>
    </div>
  </Router>
);
ReactDOM.render(routs, document.getElementById("root"));

reportWebVitals();
