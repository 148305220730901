import React, { Component } from "react";

import logo from "./virace.svg";
import { withTranslation } from "react-i18next";

export class Navbar extends Component {
  change(option) {
    localStorage.setItem("lang", option.target.value);
    window.location.reload();
  }

  render() {
    const lang = localStorage.getItem("lang") || "en";
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12 py-2">
            <div className="navbar">
              <div className="navbar-left">
                <a href="/">
                  <span className="logo-image">
                    <img src={logo} className="" height="auto" width="120px" alt="logo" />
                  </span>
                </a>
              </div>
              <div className="navbar-right-container">
                <div className="navbar-right-lang">
                  <select className="custom-select pull-right" onChange={this.change} value={lang}>
                    <option value="en">English</option>
                    <option value="fr">French</option>
                    <option value="it">Italian</option>
                    <option value="es">Spanish</option>
                    <option value="de">German</option>
               </select>
                </div>

                <div className="navbar-right"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withTranslation()(Navbar);
